import Cookies from 'js-cookie';

export default {
  get(key) {
    return Cookies.get(key);
  },
  set(key, value) {
    Cookies.set(key, value);
  },
  getJSON(key) {
    return Cookies.getJSON(key);
  },
  setJSON(key, value) {
    Cookies.setJSON(key, value);
  },
};
